export var apiUrl: string = 'https://api.getgenius.ai/api';
export var hostUrl: string = 'https://api.getgenius.ai';
export var proxyPrefix: string = 'https://search.getgenius.ai/api/searchengine/proxy.json?format=json&url=';

export var pipelineAppConfig: pipelineAppConfig = {
    allowUserCreateChat: false,
    allowUserCreateChatGroup: false,
    allowUserEditPostSettings: false,
    allowUserForgotPassword: false,
    allowUserLogin: true,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: true,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: false,
    allowUserRegisterInApp: false,
    allowUserRegisterInWeb: false,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: true,
    appDescription: 'shoep',
    appId: 'ai.shoep.studio',
    appName: 'shoep',
    appPackageName: 'shoep-studio',
    appPages: {

        main: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creator_dashboard",
                url: "/dashboard",
                icon: "grid-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_shop_admin",
                url: "/sources/shops",
                icon: "storefront-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "shop_products",
                        url: "/shop/products",
                        icon: "grid-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "shop_categories",
                        url: "/shop/categories",
                        icon: "list-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "media",
                url: "/media/library",
                icon: "images-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "media_templates",
                        url: "/media/templates",
                        icon: "eye-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ]
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "mail_inbox",
                url: "/integrations/integration/mail/inbox",
                icon: "file-tray-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "mail_crawlers",
                        url: "/integrations/integration/mail/crawlers",
                        icon: "link-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "mail_blacklist",
                        url: "/integrations/integration/mail/blacklist",
                        icon: "hand-left-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
                    },
                ]
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integrations",
                url: "/integrations",
                icon: "extension-puzzle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "settings",
                label: 'shoep_menu_settings',
                url: "/settings",
                icon: "settings-outline",
                hideIfLoggedOut: true,
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "integrations_log",
                        url: "/integrations/log",
                        icon: "list-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator'],
                        key: "teams",
                        url: "/teams",
                        icon: "people-circle-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                        key: "projects_admin",
                        url: "/projects",
                        icon: "briefcase-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                        key: "account",
                        url: "/account",
                        icon: "person-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                ]
            },
        ],
        meta_connections: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_facebook",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_instagram",
                url: "/integrations/integration/instagram/connections",
                icon: "logo-instagram",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_whatsapp",
                url: "/integrations/integration/whatsapp/connections",
                icon: "logo-whatsapp",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_comments",
                url: "/integrations/integration/meta/comments",
                icon: "chatbubble-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_log",
                url: "/integrations/integration/meta/log",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        social_media: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_linkedin",
                url: "/integrations/integration/linkedin/connections",
                icon: "logo-linkedin",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_pinterest",
                url: "/integrations/integration/pinterest/connections",
                icon: "logo-pinterest",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_reddit",
                url: "/integrations/integration/reddit/connections",
                icon: "logo-reddit",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_snapchat",
                url: "/integrations/integration/snapchat/connections",
                icon: "logo-snapchat",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_connections",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_tiktok",
                url: "/integrations/integration/tiktok/connections",
                icon: "logo-tiktok",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitch",
                url: "/integrations/integration/twitch/connections",
                icon: "logo-twitch",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitter",
                url: "/integrations/integration/twitter/connections",
                icon: "logo-twitter",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_xing",
                url: "/integrations/integration/xing/connections",
                icon: "logo-xing",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_youtube",
                url: "/integrations/integration/youtube/connections",
                icon: "logo-youtube",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        teams: [
            {
                allowedUserTypes: ['Admin', 'Moderator'],
                key: "teams",
                url: "/teams",
                icon: "people-circle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin'],
                key: "customers",
                url: "/customers-admin",
                icon: "person-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin'],
                key: "user_groups_admin",
                url: "/user-groups",
                icon: "hand-right-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
        ]
    },
    appSlogan: 'shoep',
    appVersionId: 101183,
    appVersionName: '1.1.83',
    contentHostUrl: 'https://shoep.ai/',
    dashboardWidgets: [
        
        {
            active: true,
            default: false,
            defaultSize: 6,
            icon: 'grid-outline',
            name: 'shop_categories',
            showInExpertMode: true,
            showInSimpleMode: false,
            uid: 'shop_categories',
        },
        {
            active: true,
            default: false,
            defaultSize: 6,
            icon: 'list-outline',
            name: 'shop_orders',
            showInExpertMode: true,
            showInSimpleMode: false,
            uid: 'shop_orders',
        },
        {
            active: true,
            default: false,
            defaultSize: 6,
            icon: 'grid-outline',
            name: 'shop_products',
            showInExpertMode: true,
            showInSimpleMode: false,
            uid: 'shop_products',
        },
        {
            active: true,
            default: false,
            defaultSize: 6,
            icon: 'storefront-outline',
            name: 'shop_shortcuts',
            showInExpertMode: true,
            showInSimpleMode: false,
            uid: 'shop_shortcuts',
        },
        {
            active: true,
            default: false,
            defaultSize: 6,
            icon: 'stats-chart-outline',
            name: 'statistics',
            showInExpertMode: true,
            showInSimpleMode: false,
            uid: 'statistics_overview',
        },
    ],
    environments: {
        api: {
            production: 'https://api.getgenius.ai/api',
        },
        app: {
            production: 'https://api.getgenius.ai',
        }
    },
    filterDiscoverPostsByContentHostUrl: false,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: false,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: true,
    footerBottomText: ``,
    /*
    footerBottomText: `<p>
        Erstellt mit <a href="https://pipeline.page" target="_blank">pipeline</a><br>
        &copy; ${new Date().getFullYear()} pipeline App GmbH<br><br>
        <a href="https://pipeline.page/de/agb" target="_blank">AGB</a><br>
        <a href="https://pipeline.page/de/datenschutz" target="_blank">Datenschutzerkl�rung</a>
    </p>`,
    */
    footerLoveText: 'Made with \u2764\ufe0f in Washington, D.C.',
    forceAbonnementAfterRegistration: true,
    forceProjectToBeSet: true,
    globalCreateItems: [
        {
            icon: 'images-outline',
            name: 'media',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/media/create',
        },
        {
            icon: 'cart-outline',
            name: 'product',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/shop/product',
        },
        /*
        {
            icon: 'text-outline',
            name: 'post',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/post/new',
        },
        {
            icon: 'calendar-outline',
            name: 'campaign',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/planner',
        },
        {
            icon: 'briefcase-outline',
            name: 'project',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/project',
        },
        {
            icon: 'people-circle-outline',
            name: 'team',
            requiresAboExtension: ['pro', 'enterprise'],
            url: '/team',
        },
        */
    ],
    includeRatingsInFeed: false,
    includeReactionsInFeed: false,
    includeRelatedArticlesInFeed: false,
    interruptIfWalletBalanceIsInsufficient: true,
    isWhitelabel: true,
    links: {
        imprint: 'https://www.getgenius.ai/imprint',
        privacy_policy: 'https://www.getgenius.ai/privacy-policy',
        terms_of_use: 'https://www.getgenius.ai/enterprise-terms',
    },
    loginHeadlineText: 'shoep',
    loginPageSplineSrc: './assets/spline/dani/dani.splinecode',
    loginTopLogoSize: 'large',
    loginTopLogoUrl: './assets/img/logo-dark.webp',
    maxPostAge: 365,
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: true,
    openerURL: 'https://open.getgenius.ai/',
    orderBy: {
        discover: 'post_date_gmt desc',
        home: 'post_date_gmt desc',
        local: 'post_date_gmt desc',
        people: 'RAND()',
        shop: 'name',
        showroom: 'post_date_gmt desc',
    },
    postsVipFactor: 0,
    //projectsSetupRedirectUri: '/media/create',
    //pushAppId: 'fe87ba2c-8645-41c5-b5f7-f0a81efc779b',
    registerFlow: 'passwordless',
    registerRedirectUri: '/setup',
    registerRequiresAbonnement: true,
    routes: {
        discover: '/tabs/shop/discover',
        home: '/creators-dashboard',
        local: '/tabs/local',
        people: '/tabs/people',
        shop: '/tabs/shop-categories-list',
    },
    showAccountBecomeCreator: false,
    showAccountClearCaches: true,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontsSelector: false,
    showAppearanceFontSizeSelector: false,
    showAppearancePreferedImageSize: false,
    showAppearanceTabs: false,
    showCheckoutPage: false,
    showDashboardAiShortcuts: false,
    showFeedBottomShadow: false,
    showFooterAppVersion: true,
    showFooterBottomText: true,
    showGetGeniusDaniInDashboard: false,
    showGetGeniusDaniInSidebar: false,
    showHeaderAddButton: false,
    showHeaderLargeTitle: true,
    showHeaderMenuButton: true,
    showHeaderToolbarLogo: true,
    showHeaderToolbarLogoInApp: true,
    showHeaderToolbarLogoInWeb: false,
    showHeaderToolbarTitle: false,
    showJobPostsInFeeds: false,
    showJobPostsInSearchResults: false,
    showLocalSourcesSuggestions: false,
    showLoginCredentialsSkipAccount: false,
    showLoginPage: false,
    showLoginPageLeftImg: true,
    showLoginPageLeftImgCase: true,
    showLoginPageSpline: false,
    showLoginTopLogo: true,
    showLoomaBlanketPurchaseOrders: false,
    showLoomaCustomersPersonTypeOrganization: true,
    showLoomaCustomersPersonTypePerson: true,
    showLoomaOrdersSegmentToolbar: false,
    showLoomaProductionOrders: false,
    showLoomaPurchaseOrders: false,
    showLoomaSalesOrders: true,
    showMenuAddButton: true,
    showMenuGlobalSearch: false,
    showMenuHeaderLogo: true,
    showMenuHeaderTitle: false,
    showMenuIfLoggedOut: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showPodcastsSuggestions: false,
    showPodcastsSuggestionsInFeed: false,
    showPostAuthorCard: false,
    showPostCardAdminFab: true,
    showPostCardExcerpt: true,
    showPostCardHostLabel: false,
    showPostCardOptions: false,
    showPostCardVerifiedBadges: false,
    showPostPagination: false,
    showPostReadInAppCard: false,
    showPostRelatedArticles: true,
    showProfileDisplayName: true,
    showProfileEvents: false,
    showProfileFriends: false,
    showProfileSocialInformation: false,
    showProfileSocialInformationLabels: false,
    showProfileUserGroup: false,
    showProfileUserGroupAsPrimaryLabel: false,
    showProfileUsername: true,
    showRegisterProjectSettings: true,
    showRegisterVideos: true,
    showSalut: false,
    showSettingsFooterLove: true,
    showSettingsLegalLinks: true,
    showSettingsLinks: false,
    showShopCategories: false,
    showShopNewestProducts: true,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: true,
    showShopSources: true,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: true,
    showSuggestedProfilesOnFeedPage: false,
    showSuggestedProfilesOnHomePage: false,
    showSuggestedProfilesOnLocalPage: false,
    showTabBar: true,
    showTabBarAsDock: false,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: true,
    showTabMenu: false,
    stripePublishableKey: '',
    tabBarActiveStyle: 'solid',
    tabs: [
        /*
        {
            uid: 'shopAccount',
            checked: true,
            icon: 'person-outline',
            indent: 'shop-account',
            index: 4,
            name: 'account',
            route: 'shop-account',
            url: '/tabs/shop-account',
        },
        */
    ],
    translucentFooters: true,
    translucentHeaders: true,
    useAbonnements: true,
    useAdsExtension: false,
    useAiExtension: true,
    useAppearance: false,
    useAppleWatchExtension: false,
    useArchive: false,
    useAssetsExtension: true,
    useAuthExtension: false,
    useAvatars: true,
    useBionicReadingExtension: true,
    useBlogAdminEmployeeMotivationExtension: false,
    useBlogsExtension: false,
    useCalendar: false,
    useCategories: false,
    useCategoriesOnDiscoverPage: false,
    useCategoriesOnFeedPages: false,
    useCategoriesOnHomePage: false,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: false,
    useChatExtension: false,
    useCollectionsExtension: true,
    useCompaniesExtension: false,
    useCommentsExtension: true,
    useComplexityExtension: false,
    useControlCenterExtension: true,
    useCookiesAlertInApp: false,
    useCookiesAlertInWebApp: true,
    useCouponsExtension: true,
    useCreativesExtension: true,
    useDashboardCardsWizard: false,
    useDashboardGetGeniusSearch: false,
    useDatingExtension: false,
    useDeathAnnouncementsExtension: false,
    useDiscover: true,
    useDocumentsExtension: false,
    useEventsList: false,
    useExperiments: false,
    useFeedback: false,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: false,
    useGetGeniusDashboardAiShortcuts: false,
    useGetGeniusExtension: true,
    useGetGeniusWalletExtension: false,
    useGoogleMapsExtension: false,
    useGroups: false,
    useHeadlineProfileAvatar: true,
    useHelpCenterExtension: false,
    useHome: false,
    useInbox: false,
    useInterests: false,
    useIntro: false,
    useIntroCards: true,
    useIntroPeople: false,
    useIntroReadingSettings: false,
    useInviteFriends: false,
    useJobPosts: false,
    useJobTitlesExtension: false,
    useLocal: false,
    useLoomaExtension: false,
    useMarketplaceExtension: false,
    useMediaExtension: true,
    useMoviesExtension: false,
    useNewsletterExtension: true,
    useOrderByFilters: false,
    usePayPalExtension: false,
    usePeople: false,
    usePointsSystem: false,
    usePostPaywallExtension: false,
    usePostsAdmin: true,
    useProfile: false,
    useProfileDisplayNames: true,
    useProfileTitleExtension: false,
    useProfileUsernames: true,
    useProjectsExtension: true,
    usePushNotifications: false,
    usePushNotificationsChannels: true,
    useQuizExtension: false,
    useReactions: false,
    useRegionSearch: true,
    useRegionSearchSuggestions: false,
    useReportingExtension: true,
    useSearch: false,
    useSecurityExtension: false,
    useSettings: false,
    useSharingExtension: true,
    useShop: true,
    useShopAccount: true,
    useShopCategories: true,
    useShopInternalPaymentProcess: true,
    useShortcuts: false,
    useShowroom: true,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useSimplyLocalExtension: false,
    useStatisticsExtension: true,
    useStories: false,
    useStoriesCameraExtension: true,
    useStoriesOnDiscoverPage: true,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: true,
    useSurveyExtension: false,
    useTagsExtension: true,
    useTargetGroupsExtension: true,
    useTeamsExtension: true,
    useTesting: false,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: false,
    useUsageExtension: true,
    useVideosExtension: true,
    useWallet: false,
    useWebsitesExtension: true,
    useWeclappExtension: false,
    useWeloveWalletExtension: false,
    useWhitelabelAppsExtension: true,
};